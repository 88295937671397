import { render, staticRenderFns } from "./ProblemTestPage.vue?vue&type=template&id=4a8ea10d&scoped=true&"
import script from "./ProblemTestPage.vue?vue&type=script&lang=js&"
export * from "./ProblemTestPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8ea10d",
  null
  
)

export default component.exports